<template>
    <div class="signatories">
        <div class="section-title cabinet"><span>Личный кабинет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i> подписанты</div>
        <b-tabs v-model="curTab">
            <div class="actions-tab in-tab">
                <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-text>
                        <div class="info-button">
                            <span @click="infoCostForm">Режим «Подписанты»<i class="icon icon-folder"></i></span>
                        </div>
                        <div class="info-button">
                            <a href="https://www.youtube.com/watch?v=tiCEv958CQ8" target="_blank"><span>Посмотреть видеоинструкцию <i
                                class="icon icon-video"></i></span></a>
                        </div>
                        <div class="info-text">
                            <p>Режим предназначен для ввода информации о должностных лицах, подписывающие выходные формы, формируемые системой.</p>
                        </div>
                    </b-dropdown-text>
                </b-dropdown>
            </div>
            <b-tab title="ФИО">
                <br><br>
                <div class="filter-container">
                    <b-col cols="12" md="5">
                        <multiselect v-if="version === 0"
                                     v-model="selectedGUOptions"
                                     :options="mainGuOption"
                                     placeholder="Выберите ГУ"
                                     label="text"
                                     @input="getSignatoriesList()"></multiselect>
                        <multiselect v-if="version === 1"
                                     v-model="selectedGkpk"
                                     :options="mainKGKPOption"
                                     placeholder="Выберите КГКП"
                                     label="text"
                                     @input="getSignatoriesList()"></multiselect>
                    </b-col>
                    <b-col cols="13" md="4" style="margin-right: 110px">
                        <b-form-radio-group
                            v-model="version"
                            :options="versions"
                            @change="handleVersion">
                        </b-form-radio-group>
                    </b-col>
                    <div class="right-content">
                        <div class="filter-actions">
                            <b-button v-if="access_level === 2 || access_level === 3" variant="primary" v-b-modal.add-signatory-modal  @click="handleButtonClick">
                                <i class="icon icon-plus-circle"></i> Добавить подписанта
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="table-container">
                    <b-table-simple bordered small>
                        <b-thead>
                            <b-tr>
                                <b-th></b-th>
                                <b-th>ФИО</b-th>
                                <b-th>Должность</b-th>
                                <b-th>Режимы/программы/формы расчетов</b-th>
                                <b-th>Дата начала</b-th>
                                <b-th>Дата завершения</b-th>
                                <b-th></b-th>
                            </b-tr>
                        </b-thead>
                        <vue-draggable tag="tbody" handle=".draggable-handler" v-model="selectedItem" @end="onEnd">
                            <b-tr v-for="(item, index) in signList" :key="item.id || index">
                                <b-td>
                                    <i v-if="access_level === 2 || access_level === 3" class="icon icon-drag-dots draggable-handler"></i>
                                </b-td>
                                <b-td>
                                    <p><b>{{ item.name_kz }}</b></p>
                                    <p>{{ item.name_ru }}</p>
                                </b-td>
                                <b-td>
                                    <p><b>{{ getCodeName(item.code_sign).name_kz }}</b></p>
                                    <p>{{ getCodeName(item.code_sign).name_ru }}</p>
                                </b-td>
                                <b-td>
                                    <div class="mode">
                                        <ul class="pm-list">
                                            <li class="pm-item">
                                                <span class="pm-title" v-html="getModuleName(item.code_modules.join(', '))"></span>
                                            </li>
                                        </ul>
                                        <span v-if="access_level === 2 || access_level === 3" class="add" v-b-modal.md-modal @click="setIsEditTrue(item, index)"><i
                                            class="icon icon-plus"></i> <u>Добавить режим</u></span>
                                    </div>
                                    <hr>
                                    <div class="program">
                                        <ul class="pm-list">
                                            <li class="pm-item"><span class="pm-title" v-html="getPrgName(item.code_prg.join(', '))"></span>
                                            </li>
                                        </ul>
                                        <span v-if="access_level === 2 || access_level === 3" class="add" v-b-modal.pm-modal @click="setIsEditTrue(item, index)"><i
                                            class="icon icon-plus"></i> <u>Добавить программу</u></span>
                                    </div>
                                    <hr>
                                    <div class="forms">
                                        <ul class="pm-list">
                                            <li class="pm-item">
                                                <span class="pm-title" v-html="getFormName(item.code_forms.join(', '))"></span>
                                            </li>
                                        </ul>
                                        <span v-if="access_level === 2 || access_level === 3" class="add" v-b-modal.form-modal @click="setIsEditTrue(item, index)"><i
                                            class="icon icon-plus"></i> <u>Добавить форму расчетов</u></span>
                                    </div>
                                </b-td>
                                <b-td>
                                    <p>{{ new Date(item.start_date).toLocaleDateString('en-US').replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3') }}</p>
                                </b-td>
                                <b-td>
                                    <p>{{ item.end_date ? new Date(item.end_date).toLocaleDateString('en-US').replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3') : '' }}</p>
                                </b-td>

                                <b-td>
                                    <b-dropdown v-if="access_level === 2 || access_level === 3" id="dropdown-dropleft" dropleft class="more">
                                        <template v-slot:button-content>
                                            <i class="icon icon-more"></i>
                                        </template>
                                        <b-dropdown-item @click="showModal(true, item, index)">
                                            Редактировать
                                        </b-dropdown-item>
                                        <b-dropdown-item class="remove" @click="deleteSignatories(item, index)">
                                            Удалить
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-td>
                            </b-tr>
                        </vue-draggable>
                        <b-tfoot>
                            <tr>
                                <td colspan="7" class="td-add-button">
                            <span v-if="access_level === 2 || access_level === 3" class="add-link-button" v-b-modal.add-signatory-modal
                                  @click="handleButtonClick"><i
                                class="icon icon-plus-circle"></i> <u>Добавить подписанта</u></span>
                                </td>
                            </tr>
                        </b-tfoot>
                    </b-table-simple>
                </div>

                <b-modal id="md-modal"
                         ref="modalRef"
                         size="lg"
                         centered
                         modal-class="multiselect-modal modal-table"
                >
                    <template #modal-header="{ close }">
                        <!-- Emulate built in modal header close button action -->
                        <div class="title">ДОБАВИТЬ РЕЖИМ</div>
                        <i class="icon icon-close" @click="close()"></i>
                    </template>
                    <b-form-group class="mt-3 signatories-select" label="Подписант" label-cols="2">
                        <p>{{ editItem.name_ru }}</p>
                    </b-form-group>
                    <div class="card-modal-multiselect">
                        <b-input-group size="sm" class="mb-2 search-in-modal">
                            <b-form-input type="search" class="npa-input" placeholder="Поиск" v-model="searchQuery" />
                        </b-input-group>

                        <div class="card-modal-multiselect-content">
                            <b-form-group>
                                <b-form-checkbox-group v-model="modules" @input="handleMultiSelectChange('modules', modules)">
                                    <template v-for="option in sortedOptions(selectModule)">
                                        <b-form-checkbox
                                            :key="option.code"
                                            :value="option.code"
                                            :disabled="shouldDisableOption(option.code, modules)"
                                        >
                                            <div class="d-flex" >
                                                <span>{{ option.text }}</span>
                                            </div>
                                        </b-form-checkbox>
                                    </template>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </div>
                    </div>
                    <template #modal-footer="{ close }">
                        <div>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="mr-2"
                                @click="addModule"
                            >
                                Сохранить
                            </b-button>
                            <b-button
                                variant="secondary"
                                size="sm"
                                @click="close()"
                            >
                                Отменить
                            </b-button>
                        </div>
                    </template>
                </b-modal>
                <b-modal id="pm-modal"
                         size="lg"
                         centered
                         modal-class="multiselect-modal modal-table"
                >
                    <template #modal-header="{ close }">
                        <!-- Emulate built in modal header close button action -->
                        <div class="title">ДОБАВИТЬ ПРОГРАММУ</div>
                        <i class="icon icon-close" @click="close()"></i>
                    </template>
                    <b-form-group class="mt-3 signatories-select" label="Подписант" label-cols="2">
                        <p>{{ editItem.name_ru }}</p>
                    </b-form-group>
                    <div class="card-modal-multiselect">
                        <b-input-group size="sm" class="mb-2 search-in-modal">
                            <b-form-input type="search" class="npa-input" placeholder="Поиск" v-model="searchQuery" />
                        </b-input-group>
                        <div class="card-modal-multiselect-content">
                            <b-form-group>
                                <b-form-checkbox-group v-model="selectPrg" @input="handleMultiSelectChange('selectPrg', selectPrg)">
                                    <template v-for="option in sortedOptions(selectPrgOptions)">
                                        <b-form-checkbox
                                            :key="option.code"
                                            :value="option.code"
                                            :disabled="shouldDisableOption(option.code, selectPrg)"
                                        >
                                            <div class="d-flex" >
                                                <span>{{ option.text }}</span>
                                            </div>
                                        </b-form-checkbox>
                                    </template>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </div>
                    </div>
                    <template #modal-footer="{ close }">
                        <div>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="mr-2"
                                @click="addProgram"
                            >
                                Сохранить
                            </b-button>
                            <b-button
                                variant="secondary"
                                size="sm"
                                @click="close()"
                            >
                                Отменить
                            </b-button>
                        </div>
                    </template>
                </b-modal>
                <b-modal id="form-modal"
                         size="lg"
                         centered
                         modal-class="multiselect-modal modal-table"
                >
                    <template #modal-header="{ close }">
                        <!-- Emulate built in modal header close button action -->
                        <div class="title">ДОБАВИТЬ ФОРМУ РАСЧЕТОВ</div>
                        <i class="icon icon-close" @click="close()"></i>
                    </template>
                    <b-form-group class="mt-3 signatories-select" label="Подписант" label-cols="2">
                        <p>{{ editItem.name_ru }}</p>
                    </b-form-group>
                    <div class="card-modal-multiselect">
                        <b-input-group size="sm" class="mb-2 search-in-modal">
                            <b-form-input type="search" class="npa-input" placeholder="Поиск" v-model="searchQuery" />
                        </b-input-group>
                        <div class="card-modal-multiselect-content">
                            <b-form-group>
                                <b-form-checkbox-group v-model="PayForm"  @input="handleMultiSelectChange('PayForm', PayForm)">
                                    <template v-for="option in sortedOptions(selectPayForm)">
                                        <b-form-checkbox
                                            :key="option.code"
                                            :value="option.code"
                                            :disabled="shouldDisableOption(option.code, PayForm)"
                                        >
                                            <div class="d-flex" >
                                                <span>{{ option.text }}</span>
                                            </div>
                                        </b-form-checkbox>
                                    </template>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </div>
                    </div>
                    <template #modal-footer="{ close }">
                        <div>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="mr-2"
                                @click="addForm"
                            >
                                Сохранить
                            </b-button>
                            <b-button
                                variant="secondary"
                                size="sm"
                                @click="close()"
                            >
                                Отменить
                            </b-button>
                        </div>
                    </template>
                </b-modal>
            </b-tab>
            <b-tab title="РЕЖИМЫ">
                <br><br>
                <div class="filter-container">
                    <b-col cols="12" md="5">
                        <multiselect
                                     v-if="version === 0"
                                     v-model="selectedGUOptions"
                                     :options="mainGuOption"
                                     placeholder="Выберите ГУ"
                                     label="text"
                                     @input="getSignatoriesList"></multiselect>
                        <multiselect v-if="version === 1"
                                     v-model="selectedGkpk"
                                     :options="mainKGKPOption"
                                     placeholder="Выберите КГКП"
                                     label="text"
                                     @input="getSignatoriesList"></multiselect>
                    </b-col>
                    <b-col cols="12" md="6" style="margin-right: 110px">
                        <b-form-radio-group
                            v-model="version"
                            :options="versions"
                            @change="handleVersion">
                        </b-form-radio-group>
                    </b-col>
                </div>
                <div class="table-container">
                    <b-table-simple bordered small>
                        <b-thead>
                            <b-tr>
                                <b-th>Режим</b-th>
                                <b-th>ФИО</b-th>
                                <b-th>Программа</b-th>
                                <b-th>Должность</b-th>
                            </b-tr>
                        </b-thead>
                        <vue-draggable tag="tbody" handle=".draggable-handler" v-model="selectedItem">
                            <b-tr v-for="(item, index) in moduleList" :key="item.id || index">
                                <b-td>
                                    <p><b>{{ getModuleName(item) }}</b></p>
                                </b-td>
                                <b-td>
                                    <div v-for="(sign, sIndex) in filteredSignList(item)" :key="sign.id || sIndex">
                                        <p v-if="sIndex === 0"><b>{{ sign.name_ru }}</b></p>
                                        <p v-else><b>{{ sign.name_ru }}</b></p>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div>
                                        <div v-for="(sign, sIndex) in filteredSignList(item)" :key="sign.id || sIndex">
                                            <p v-if="sign.code_prg.length === 0">&nbsp;</p>
                                            <p v-else><b>{{ sign.code_prg.join(', ') }}</b></p>
                                        </div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div v-for="(sign, sIndex) in filteredSignList(item)" :key="sign.id || sIndex">
                                        <p v-if="sIndex === 0"><b>{{ getCodeName(sign.code_sign).name_ru }}</b></p>
                                        <p v-else><b>{{ getCodeName(sign.code_sign).name_ru }}</b></p>
                                    </div>
                                </b-td>
                            </b-tr>
                        </vue-draggable>
                    </b-table-simple>



                </div>
            </b-tab>
        </b-tabs>

        <b-modal id="add-signatory-modal"
                 v-model="modalForm"
                 size="lg"
                 centered
                 modal-class="multiselect-modal modal-table"
        >
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <div class="title">{{ isEdit ? 'Редактировать подписанта:' : 'Добавить подписанта:' }}</div>
                <i class="icon icon-close" @click="close()"></i>
            </template>
            <div class="card-modal-multiselect">
                <b-form-group label="Регион" label-cols="3" class="form-group-divider">
                    <multiselect
                        :options="selectRegion"
                        v-model="region"
                        :preselect="isEdit ? this.editItem.code_region : region"
                        label="text"
                        :placeholder="isEdit ?  this.editItem.code_region : 'Введите регион'"
                        :value="region"
                        @input="loadAbp"
                    />

                </b-form-group>
                <b-form-group label="АБП" label-cols="3" class="form-group-divider">
                    <multiselect
                        :options="selectAbp"
                        v-model="abp"
                        :preselect="isEdit ? this.editItem.code_abp : abp"
                        label="text"
                        :placeholder="isEdit ?  this.editItem.code_abp.toString() : 'Введите АБП'"
                        @input="() => { loadPrg(); loadGU(); loadKGKP(); }"/>
                </b-form-group>
                <b-form-group v-if="version === 0" label="ГУ" label-cols="3" class="form-group-divider">
                    <multiselect
                        v-if="version === 0"
                        :options="formattedOptions"
                        v-model="selectedGu"
                        :preselect="isEdit ? this.editItem.code_gu : selectedGu"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        label="label"
                        :placeholder="isEdit ?  this.editItem.code_gu.toString() : 'Введите ГУ'"
                        @input="loadUser"/>
                </b-form-group>
                <b-form-group v-if="version === 1" label="КГКП" label-cols="3" class="form-group-divider">
                    <multiselect
                        v-if="version === 1"
                        :options="formattedKGKPOptions"
                        v-model="selectedKGKP"
                        label="label"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        :placeholder="isEdit ?  this.editItem.code_gu.toString() : 'Введите КГКП'"
                        @input="loadUserKGKP"/>
                </b-form-group>
                <b-form-group label="Пользователь" label-cols="3" class="form-group-divider">
                    <multiselect
                        :options="selectUserOption"
                        v-model="users"
                        :preselect="isEdit ? this.editItem.name_ru : users"
                        label="name"
                        :placeholder="isEdit ?  this.editItem.name_ru : 'Введите пользователя'"/>
                </b-form-group>
                <b-form-group label="Должность" label-cols="3" class="form-group-divider">
                    <multiselect
                        :options="selectPosition"
                        v-model="position"
                        :preselect="isEdit ? this.editItem.code_sign : position"
                        label="name_ru"
                        :placeholder="isEdit ? getCodeName(this.editItem.code_sign).name_ru : 'Введите должность'"/>
                </b-form-group>
                <b-form-group label="ФИО на казахском" label-cols="3" class="form-group-divider label-bold">
                    <b-form-textarea v-model="kazakhName"
                                     :preselect="isEdit ? this.editItem.name_kz : kazakhName"
                                     :placeholder="isEdit ?  this.editItem.name_kz : 'Введите ФИО на казахском'"/>
                </b-form-group>
                <b-form-group label="ФИО на русском" label-cols="3" class="m-0 form-group-divider label-bold">
                    <b-form-textarea v-model="russianName"
                                     :preselect="isEdit ? this.editItem.name_ru : russianName"
                                     :placeholder="isEdit ?  this.editItem.name_ru : 'Введите ФИО на казахском'"/>

                </b-form-group>
                <b-form-group label="Модуль/режим" label-cols="3" class="form-group-divider">
                    <div class="input-style modal-multiselect-trigger" v-b-modal.multiselect-modal @click="modules_form = true">
                        <span class="selected">{{
                                modules.includes('all')
                                    ? 'Все'
                                    : modules.length > 0
                                        ? 'Выбрано ' + modules.length
                                        : 'Выберите модуль/режим'
                            }}</span>
                        <i class="icon icon-plus-circle"></i>
                    </div>
                    <b-modal id="multiselect-modal"
                             v-model="modules_form"
                             size="lg"
                             centered
                             modal-class="multiselect-modal modal-table"
                    >
                        <template #modal-header="{ close }">
                            <!-- Emulate built in modal header close button action -->
                            <div class="title">Выберите модуль/режим:</div>
                            <i class="icon icon-close" @click="close()"></i>
                        </template>
                        <div class="card-modal-multiselect">
                            <b-input-group size="sm" class="mb-2 search-in-modal">
                                <b-form-input type="search" class="npa-input" placeholder="Поиск" v-model="searchQuery" />
                            </b-input-group>
                            <div class="card-modal-multiselect-content">
                                <b-form-group>
                                    <b-form-checkbox-group v-model="modules"  @input="handleMultiSelectChange('modules', modules)">
                                        <template v-for="option in sortedOptions(selectModule)">
                                            <b-form-checkbox
                                                :key="option.code"
                                                :value="option.code"
                                                :disabled="shouldDisableOption(option.code, modules)"
                                            >
                                                <div class="d-flex" >
                                                    <span>{{ option.text }}</span>
                                                </div>
                                            </b-form-checkbox>
                                        </template>
                                    </b-form-checkbox-group>
                                </b-form-group>
                            </div>

                        </div>

                        <template #modal-footer>
                            <div>
                                <b-button
                                    variant="primary"
                                    size="sm"
                                    class="mr-2"
                                    @click="$root.$emit('bv::hide::modal', 'multiselect-modal')"
                                >
                                    Применить
                                </b-button>
                                <b-button
                                    variant="secondary"
                                    size="sm"
                                    @click="modules = []"
                                >
                                    Сбросить
                                </b-button>
                            </div>
                        </template>
                    </b-modal>
                </b-form-group>
                <b-form-group label="Форма расчетов" label-cols="3" class="form-group-divider">
                    <div class="input-style modal-multiselect-trigger" v-b-modal.multiselect-payform
                         @click="payforms_form = true">
        <span class="selected">{{
                PayForm.includes('all')
                    ? 'Все'
                    : PayForm.length > 0
                        ? 'Выбрано ' + PayForm.length
                        : 'Выберите форму расчетов' }}</span>
                        <i class="icon icon-plus-circle"></i>
                    </div>
                    <b-modal id="multiselect-payform"
                             v-model="payforms_form"
                             size="lg"
                             centered
                             modal-class="multiselect-modal modal-table"
                    >
                        <template #modal-header="{ close }">
                            <!-- Emulate built in modal header close button action -->
                            <div class="title">Выберите форму расчетов:</div>
                            <i class="icon icon-close" @click="close()"></i>
                        </template>
                        <div class="card-modal-multiselect">
                            <b-input-group size="sm" class="mb-2 search-in-modal">
                                <b-form-input type="search" class="npa-input" placeholder="Поиск" v-model="searchQuery" />
                            </b-input-group>
                            <div class="card-modal-multiselect-content">
                                <b-form-group>
                                    <b-form-checkbox-group v-model="PayForm"  @input="handleMultiSelectChange('PayForm', PayForm)">
                                        <template v-for="option in sortedOptions(selectPayForm)">
                                            <b-form-checkbox
                                                :key="option.code"
                                                :value="option.code"
                                                :disabled="shouldDisableOption(option.code, PayForm)"
                                            >
                                                <div class="d-flex" >
                                                    <span>{{ option.text }}</span>
                                                </div>
                                            </b-form-checkbox>
                                        </template>
                                    </b-form-checkbox-group>
                                </b-form-group>
                            </div>

                        </div>

                        <template #modal-footer>
                            <div>
                                <b-button
                                    variant="primary"
                                    size="sm"
                                    class="mr-2"
                                    @click="$root.$emit('bv::hide::modal', 'multiselect-payform')"
                                >
                                    Применить
                                </b-button>
                                <b-button
                                    variant="secondary"
                                    size="sm"
                                    @click="PayForm = []"
                                >
                                    Сбросить
                                </b-button>
                            </div>
                        </template>
                    </b-modal>
                </b-form-group>
                <b-form-group label="Программа" label-cols="3" class="form-group-divider">
                    <div class="input-style modal-multiselect-trigger" v-b-modal.multiselect-prg
                         @click="prg_form = true">
        <span class="selected">{{
                selectPrg.includes('all')
                    ? 'Все'
                    : selectPrg.length > 0
                        ? 'Выбрано ' + selectPrg.length
                        : 'Выберите программу' }}</span>
                        <i class="icon icon-plus-circle"></i>
                    </div>
                    <b-modal id="multiselect-prg"
                             v-model="prg_form"
                             size="lg"
                             centered
                             modal-class="multiselect-modal modal-table"
                    >
                        <template #modal-header="{ close }">
                            <!-- Emulate built in modal header close button action -->
                            <div class="title">Выберите программу:</div>
                            <i class="icon icon-close" @click="close()"></i>
                        </template>
                        <div class="card-modal-multiselect">
                            <b-input-group size="sm" class="mb-2 search-in-modal">
                                <b-form-input type="search" class="npa-input" placeholder="Поиск" v-model="searchQuery" />
                            </b-input-group>
                            <div class="card-modal-multiselect-content">
                                <b-form-group>
                                    <b-form-checkbox-group v-model="selectPrg" @input="handleMultiSelectChange('selectPrg', selectPrg)">
                                        <template v-for="option in sortedOptions(selectPrgOptions)">
                                            <b-form-checkbox
                                                :key="option.code"
                                                :value="option.code"
                                                :disabled="shouldDisableOption(option.code, selectPrg)"
                                            >
                                                <div class="d-flex" >
                                                    <span>{{ option.text }}</span>
                                                </div>
                                            </b-form-checkbox>
                                        </template>
                                    </b-form-checkbox-group>
                                </b-form-group>
                            </div>

                        </div>

                        <template #modal-footer>
                            <div>
                                <b-button
                                    variant="primary"
                                    size="sm"
                                    class="mr-2"
                                    @click="$root.$emit('bv::hide::modal', 'multiselect-prg')"
                                >
                                    Применить
                                </b-button>
                                <b-button
                                    variant="secondary"
                                    size="sm"
                                    @click="selectPrg = []"
                                >
                                    Сбросить
                                </b-button>
                            </div>
                        </template>
                    </b-modal>
                </b-form-group>
                <br>
                <b-form-group label="Дата начала действия:" label-cols-md="3" class="bolded-label">
                    <date-picker
                        placeholder="Выберите дату"
                        v-model="DateFrom"
                        format="DD/MM/YYYY"
                    />
                </b-form-group>
                <br>
                <b-form-group label="Дата завершения действия:" label-cols-md="3" class="bolded-label">
                    <date-picker
                        placeholder="Выберите дату"
                        v-model="DateTo"
                        format="DD/MM/YYYY"
                    />
                </b-form-group>

            </div>
            <template #modal-footer="{ close }">
                <div class="w-100 text-center">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="mr-2"
                        @click="isEdit ? update() : add()"
                    >
                        {{ isEdit ? 'Сохранить' : 'Добавить' }}
                    </b-button>
                    <b-button
                        variant="secondary"
                        size="sm"
                        @click="close()"
                    >
                        Отменить
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

import VueDraggable from 'vuedraggable';
import DatePicker from 'vue2-datepicker';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

export default {
    name: 'signatories',
    components: {VueDraggable, DatePicker, Multiselect},
    data() {
        return {
            text: null,
            signList: [],
            moduleList: [],
            signNames: {},
            curSigner: [],
            curParams: {},
            modalForm: false,
            modules_form: false,
            payforms_form: false,
            prg_form: false,
            isEdit: false,
            selectPosition: [],
            position: null,
            selectUser: [],
            users: null,
            kazakhName: '',
            russianName: '',
            selectModule: [],
            modules: [],
            selectedOptions: [],
            selectPayForm: [],
            PayForm: [],
            curTab: 0,
            selectProgram: [],
            selectPrg: [],
            selectGu: [],
            selectKGKP: [],
            selectedKGKP: null,
            getGu: [],
            gu_code: [],
            kgkp_code: [],
            selectedGu: null,
            selectedGuValue: null,
            selectedGUOptions: null,
            selectedGkpk: null,
            selectAbp: [],
            selectedItem: null,
            guOptions: [],
            selected_Gu: null,
            abp: null,
            obl: null,
            order: [],
            code: null,
            name_ru: null,
            selectRegion: [],
            region: null,
            editItem: {},
            access_level: 1,
            DateFrom: new Date().toISOString().split('T')[0],
            DateTo: null,
            searchQuery: '',
            allOptionCode: "all",
            showAllOption: true,
            version: 0,
            versions: [
                { value: 0, text: 'ГУ' },
                { value: 1, text: 'КГКП' }
            ],
        }
    },

    async mounted() {
        await this.getAccessLevel();
        await this.getObl();
        await this.loadSignatories();
        await this.loadModules();
        await this.loadForms();
        await this.getGU();

    },
    created() {
        this.DateFrom = new Date(this.DateFrom);
    },
    watch: {
        users(newUser) {
            if (newUser) {
                this.kazakhName = newUser.kazakhName || '';
                this.russianName = newUser.russianName || '';
            } else {
                this.kazakhName = '';
                this.russianName = '';
            }
        },
        region(){
            this.abp = null;
        },
        abp(newVal, oldVal) {
            this.selectedGu = null;
            this.selectedKGKP = null;
            this.selectGu = [];
            this.selectKGKP = [];
            if (newVal !== oldVal) {
                this.selectPrg = [];
            }
        },
        selectedGu() {
            this.users = null;
            this.selectUser = [];
        },
        version(newVersion) {
            if (newVersion === 0) {
                this.selectedGkpk = null;
            } else if (newVersion === 1) {
                this.selectedGUOptions = null;
            }
        }

    },

    computed: {
        sortedOptions() {
            return (options) => {
                const sorted = [...this.filteredOptions(options)];
                sorted.sort((a, b) => {
                    if (this.isEdit) {
                        if (options === this.selectModule) {
                            if (this.editItem.code_modules.includes(a.code) && !this.editItem.code_modules.includes(b.code)) {
                                return -1;
                            }
                            if (this.editItem.code_modules.includes(b.code) && !this.editItem.code_modules.includes(a.code)) {
                                return 1;
                            }
                        } else if (options === this.selectPayForm) {
                            if (this.editItem.code_forms.includes(a.code) && !this.editItem.code_forms.includes(b.code)) {
                                return -1;
                            }
                            if (this.editItem.code_forms.includes(b.code) && !this.editItem.code_forms.includes(a.code)) {
                                return 1;
                            }
                        } else if (options === this.selectPrgOptions) {
                            if (this.editItem.code_prg.includes(a.code) && !this.editItem.code_prg.includes(b.code)) {
                                return -1;
                            }
                            if (this.editItem.code_prg.includes(b.code) && !this.editItem.code_prg.includes(a.code)) {
                                return 1;
                            }
                        }
                    }
                    return 0;
                });
                return sorted;
            };
        },

        filteredSignList() {
            return (item) => {
                return this.signList.filter((sign) => {
                    return sign.code_modules.includes(item);
                });

            };
        },
        mainGuOption() {
            return this.gu_code.map(option => ({
                text: `${option.code} - ${option.name_ru}`,
                code: option.code
            }))
        },
        mainKGKPOption() {
            return this.kgkp_code.map(option => ({
                text: `${option.bin} - ${option.name_ru}`,
                code: option.bin
            }))
        },
        formattedOptions() {
            return this.selectGu.map(option => ({
                label: `${option.code} - ${option.name_ru}`,
                code: option.code
            }));
        },
        formattedKGKPOptions() {
            return this.selectKGKP.map(option => ({
                label: `${option.bin} - ${option.name_ru}`,
                code: option.bin
            }));
        },
        selectUserOption() {
            return this.selectUser.map(option => ({
                name: option.lastName ? `${option.firstName} ${option.lastName}` : `${option.firstName} `,
                kazakhName: option.lastName ? `${option.firstName} ${option.lastName}` : `${option.firstName} `,
                russianName: option.lastName ? `${option.firstName} ${option.lastName}` : `${option.firstName} `,
                username: `${option.username}`,
                id_user: `${option.id_user}`
            }));
        },
        selectPrgOptions() {
            const options = this.selectProgram.map(option => ({
                text: `${option.prg} - ${option.name_ru}`,
                code: option.prg
            }));

            if (this.showAllOption) {
                options.unshift({
                    text: "Все",
                    code: "all"
                });
            }

            return options;
        }
    },

    methods: {
        async handleVersion() {
            if (this.version === 0) {
                await this.getGU();
            } else if (this.version === 1) {
                await this.getKGPK();
            }
        },
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/100.000.001`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        async infoCostForm() {
            if (this.isLoad) {
                this.makeToast('info', 'Внимание', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
            } else {
                this.isLoad = true;
                await axios({
                    method: 'post',
                    url: '/api-py/get-info/29',
                    responseType: 'blob',
                    data: null
                })
                    .then((response) => response.data)
                    .then((data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const titleOfFile = 'Инструкция_Подписанты';
                        link.setAttribute('download', titleOfFile + '.pdf');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // handle success
                    })
                    .catch((error) => {
                        console.log(error);
                        this.makeToast('danger', 'Ошибка запроса infoCostForm()', error.toString());
                    })
                    .finally(() => {
                        this.isLoad = false;
                    });
            }
        },
        handleMultiSelectChange(property, selection) {
            if (selection.includes('all')) {
                this[property] = ['all'];
            } else {
                this[property] = selection.filter(option => option !== 'all');
            }
        },
        filteredOptions(options) {
            const query = this.searchQuery.toLowerCase().trim();
            if (query === '') {
                return options;
            } else {
                return options.filter(option =>
                    option.text.toLowerCase().includes(query)
                );
            }
        },
        shouldDisableOption(code, options) {
            if (options.includes('all')) {
                return code !== 'all';
            }
            return false;
        },
        handleButtonClick() {
            if (this.version === 0) {
                this.getRegAbpGu();
            } else if (this.version === 1) {
                this.getRegAbpKGKP();
            }
            this.showModal(false);
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.$store.getters.user_uuid);
                this.selectRegion = await response.json();
                await this.loadAbp();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },
        async getSignatoriesList() {
            if (this.selectedGUOptions !== null || this.selectedGkpk !== null) {
                this.signList = [];
                if (this.version === 0){
                    const response = await fetch(`/api-py/get_list_of_signatories/` + this.selectedGUOptions.code);
                    const data = await response.json();
                    this.signList = data.result;
                    this.moduleList = data.modules;
                }
                else if (this.version === 1) {
                    const response = await fetch(`/api-py/get_list_of_signatories/` + this.selectedGkpk.code);
                    const data = await response.json();
                    this.signList = data.result;
                    this.moduleList = data.modules;
                }
                await this.loadPrgforEdit(this.selectedGUOptions.code.substring(0, 3));
            }
        },
        // прогружает регион, абп, гу после того как пользователь выбрал ГУ
        async getRegAbpGu() {
            if (this.selectedGUOptions !== null) {
                const response = await fetch(`/api-py/select_region_abp_gu/` + this.selectedGUOptions.code);
                const data = await response.json();
                const reg = data.region;
                const get_abp = data.abp;
                const get_gu = data.gu;

                const selectedRegOption = this.selectRegion.find(option => option.code === reg);
                if (selectedRegOption && selectedRegOption.code === reg) {
                    this.region = selectedRegOption;
                } else {
                    this.region = null;
                }
                await this.loadAbp();
                const selectedAbpOption = this.selectAbp.find(option => option.text.substring(0, 3) === get_abp);
                if (selectedAbpOption) {
                    this.abp = selectedAbpOption;
                }
                await this.loadPrg();
                await this.loadGU();
                const selectedGuOption = this.selectGu.find(option => option.code === get_gu);
                if (selectedGuOption) {
                    this.selectedGu = {
                        code: selectedGuOption.code,
                        label: `${selectedGuOption.code} - ${selectedGuOption.name_ru}`
                    };
                }
                await this.loadUser();

            }
        },
        // прогружает регион, абп, кгкп после того как пользователь выбрал КГКП
        async getRegAbpKGKP() {
            if (this.selectedGkpk !== null) {
                const response = await fetch(`/api-py/select_region_abp_kgkp/` + this.selectedGkpk.code);
                const data = await response.json();
                const reg = data.region;
                const get_abp = data.abp;
                const get_kgkp = data.kgkp;

                const selectedRegOption = this.selectRegion.find(option => option.code === reg);
                if (selectedRegOption && selectedRegOption.code === reg) {
                    this.region = selectedRegOption;
                } else {
                    this.region = null;
                }
                await this.loadAbp();
                const selectedAbpOption = this.selectAbp.find(option => option.text.substring(0, 3) === get_abp);
                if (selectedAbpOption) {
                    this.abp = selectedAbpOption;
                }
                await this.loadPrg();
                await this.loadKGKP();
                const selectedKGKPOption = this.selectKGKP.find(option => option.bin === get_kgkp);
                if (selectedKGKPOption) {
                    this.selectedKGKP = {
                        code: selectedKGKPOption.bin,
                        label: `${selectedKGKPOption.bin} - ${selectedKGKPOption.name_ru}`
                    };
                }
                await this.loadUserKGKP();

            }
        },
        // загрузка должностей для пользователей
        async loadSignatories() {
            try {
                const response = await fetch('/api-py/list_of_signatories');
                this.selectPosition = await response.json();

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса должностей', error.toString());
            }
        },
        getCodeName(code) {
            const position = this.selectPosition.find((pos) => pos.code === code);
            return position ? {name_ru: position.name_ru, name_kz: position.name_kz} : {name_ru: '', name_kz: ''};
        },
        async loadModules() {
            try {
                const response = await fetch('/api-py/list_of_modules');
                this.selectModule = await response.json();

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса модуль/режим', error.toString());
            }
        },
        getModuleName(code) {
            const codes = code.split(',').sort();
            if (codes.length === this.selectModule.length - 1) {
                return 'Все режимы';
            } else {
                const moduleNames = codes.map(code => {
                    const module = this.selectModule.find(pos => pos.code === code.trim());
                    return module ? module.name_ru : '';
                });
                const limit = 4; // Maximum number of modules per line
                const result = [];
                for (let i = 0; i < moduleNames.length; i += limit) {
                    result.push(moduleNames.slice(i, i + limit).join(', '));
                }
                return result.join('<br>');
            }
        },
        getPrgName(code) {
            const codes = code.split(',').sort();
            if (codes.length === this.selectProgram.length) {
                return 'Все программы';
            } else {
                return code
            }
        },
        getFormName(code) {
            const codes = code.split(',').sort();
            if (codes.length === this.selectPayForm.length - 1) {
                return 'Все формы расчетов';
            } else {
                const itemsPerLine = 12;
                const formattedCodes = [];

                for (let i = 0; i < codes.length; i += itemsPerLine) {
                    const line = codes.slice(i, i + itemsPerLine).join(', ');
                    formattedCodes.push(line);
                }
                return formattedCodes.join('<br>');
            }
        },
        // загрузка форм расчетов
        async loadForms() {
            try {
                const response = await fetch('/api-py/list_of_payforms');
                this.selectPayForm = await response.json();

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса модуль/режим', error.toString());
            }
        },
        async loadAbpforEdit(region) {
            let endpointUrl = '';
            if (region !== null) {
                endpointUrl = '/api-py/get_abp_by_region_user_id/' + region + '/' + this.$store.getters.user_uuid + '/' + this.version;
            }
            try {
                const response = await fetch(endpointUrl);
                this.selectAbp = await response.json();
                await this.loadPrgforEdit();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса АБП2', error.toString());
            }
        },
        // загрузка АБП по региону, user_id; версия для определения ГУ(0) и КГКП(1)
        async loadAbp() {
            if (this.region !== null) {
                try {
                    const response = await fetch('/api-py/get_abp_by_region_user_id/' + this.region.code + '/' + this.$store.getters.user_uuid + '/' + this.version);
                    const abps = await response.json();
                    const map = new Map();
                    abps.forEach(item => {
                        map.set(item.abp, item);
                    });

                    this.selectAbp = Array.from(map.values());
                    await this.loadPrg();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса АБП', error.toString());
                }
            }
        },
        async loadPrgforEdit(abp) {
            let endpointUrl = '';
            if (this.isEdit) {
                endpointUrl = '/api-py/actual-prg-by-abp/' + this.editItem.code_abp;
            } else {
                endpointUrl = '/api-py/actual-prg-by-abp/' + abp;
            }
            try {
                const response = await fetch(endpointUrl);
                this.selectProgram = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса Программы', error.toString());
            }
        },
        // загрузка Программы по АБП
        async loadPrg() {
            if (this.abp !== null) {
                try {
                    const response = await fetch('/api-py/actual-prg-by-abp/' + this.abp.abp);
                    this.selectProgram = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса Программы', error.toString());
                }
            }
        },
        // загрузка ГУ по user_id, отображается на главной вкладке(version = 0)
        async getGU() {
            try {
                const response = await fetch('/api-py/dict-gu-by-userid/' + this.$store.getters.user_uuid);
                this.gu_code = await response.json();
                this.gu_code.sort((a, b) => (a.code > b.code) ? 1 : -1);

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса GetGu2', error.toString());
            }
        },
        // загрузка КГКП по user_id, отображается на главной вкладке(version = 1)
        async getKGPK() {
            try {
                const response = await fetch('/api-py/dict-kgkp-by-userid/' + this.$store.getters.user_uuid + '/' + moment(this.DateFrom).format('YYYY-MM-DD'));
                this.kgkp_code = await response.json();
                this.kgkp_code.sort((a, b) => (a.bin > b.bin) ? 1 : -1);

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса GetGu2', error.toString());
            }
        },
        // загрузка ГУ по АБП и user_id
        async loadGU() {
            if (this.region !== null && this.abp !== null) {
                try {
                    const response = await fetch('/api-py/get-gu-by-abp-user-id/' + this.abp.abp + '/' + this.$store.getters.user_uuid);
                    this.selectGu = await response.json();
                    this.selectGu.sort((a, b) => (a.code > b.code) ? 1 : -1);
                    await this.loadUser();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса ГУ', error.toString());
                }
            }
        },
        // загрузка КГКП по АБП, user_id
        async loadKGKP() {
            if (this.region !== null && this.abp !== null) {
                try {
                    const response = await fetch('/api-py/get-kgkp-by-abp-user-id/' + this.abp.abp + '/' + this.$store.getters.user_uuid);
                    this.selectKGKP = await response.json();
                    this.selectKGKP.sort((a, b) => (a.bin > b.bin) ? 1 : -1);
                    await this.loadUserKGKP();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса КГКП', error.toString());
                }
            }
        },
        // загрузка пользователей по ГУ
        async loadUser() {
            if (this.selectedGu !== null) {
                try {
                    const response = await fetch('/api-py/gu-users/' + this.selectedGu.code);
                    this.selectUser = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса Пользователя', error.toString());
                }
            }
        },
        // загрузка пользователей по КГКП
        async loadUserKGKP() {
            if (this.selectedKGKP !== null) {
                try {
                    const response = await fetch('/api-py/kgkp-users_for_signatories/' + this.selectedKGKP.code);
                    this.selectUser = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса Пользователя', error.toString());
                }
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        setIsEditTrue(item, index) {
            this.isEdit = true;
            this.editItem = Object.assign({}, item);
            if (this.isEdit) {
                this.editItem.code_region = this.signList[index].code_region;
                this.modules = this.signList[index].code_modules;
                this.selectPrg = this.signList[index].code_prg;
                this.PayForm = this.signList[index].code_forms;
            } else {
                this.editItem = {};
            }
            this.loadAbpforEdit(this.editItem.code_region);

        },
        showModal(isEdit, item, index) {
            this.isEdit = isEdit;
            this.modalForm = true;
            this.users = null;
            this.region = null;
            this.position = null;
            this.kazakhName = '';
            this.russianName = '';
            this.DateFrom = new Date();
            this.DateTo = null;
            this.modules = [];
            this.PayForm = [];
            this.selectPrg = [];
            this.editItem = Object.assign({}, item);
            if (this.isEdit) {
                this.DateFrom = new Date(this.editItem.start_date);
                this.DateTo = this.editItem.end_date ? new Date(this.editItem.end_date) : null;
                this.editItem.code_region = this.signList[index].code_region;
                this.editItem.code_abp = this.signList[index].code_abp;
                this.editItem.code_sign = this.signList[index].code_sign;
                this.modules = [...item.code_modules];
                this.selectPrg = [...item.code_prg];
                this.PayForm = [...item.code_forms];
                this.editItem.code_gu = this.signList[index].code_gu;
                this.editItem.id_user = this.signList[index].id_user;
                this.editItem.name_ru = this.signList[index].name_ru;
                this.editItem.name_kz = this.signList[index].name_kz;
                this.editItem.user_name = this.signList[index].user_name;
                this.editItem.code_sign = this.signList[index].code_sign;
            } else {
                this.editItem = {};
            }
        },
        async addModule() {
            const item = [{
                id: this.editItem.id,
                code_modules: this.modules.length > 0 && this.modules[0] === 'all'
                    ? this.selectModule.slice(1).map(option => option.code)
                    : this.modules,
                update_date: moment().format('YYYY-MM-DD HH:mm:ss')
            }]
            try {
                const response = await fetch('/api-py/add-module', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Изменения успешно сохранены');
                    await this.getSignatoriesList();
                } else {
                    throw 'Ошибка сохранения данных';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            }
            this.$root.$emit('bv::hide::modal', 'md-modal');
        },
        async addProgram() {
            const item = [{
                id: this.editItem.id,
                code_prg: this.selectPrg.length > 0 && this.selectPrg[0] === 'all'
                    ? this.selectPrgOptions.slice(1).map(option => option.code)
                    : this.selectPrg,
                update_date: moment().format('YYYY-MM-DD HH:mm:ss')
            }]

            try {
                const response = await fetch('/api-py/add-program', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Изменения успешно сохранены');
                    await this.getSignatoriesList();
                } else {
                    throw 'Ошибка сохранения данных';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            }
            this.$root.$emit('bv::hide::modal', 'pm-modal');
        },
        async addForm() {
            const item = [{
                id: this.editItem.id,
                code_forms: this.PayForm.length > 0 && this.PayForm[0] === 'all'
                    ? this.selectPayForm.slice(1).map(option => option.code)
                    : this.PayForm,
                update_date: moment().format('YYYY-MM-DD HH:mm:ss')
            }]

            try {
                const response = await fetch('/api-py/add-form', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Изменения успешно сохранены');
                    await this.getSignatoriesList();
                } else {
                    throw 'Ошибка сохранения данных';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            }
            this.$root.$emit('bv::hide::modal', 'form-modal');
        },
        onEnd(event) {
            const {oldIndex, newIndex} = event;
            const movedItem = this.signList[oldIndex];
            const swappedItem = this.signList[newIndex];
            let itemsToSwap;

            if (movedItem.order_num < swappedItem.order_num) {
                itemsToSwap = [movedItem, swappedItem];
            } else {
                itemsToSwap = [swappedItem, movedItem];
            }
            axios.put('/api-py/order_num_swap', itemsToSwap)
                .catch(error => console.error(error));
        },

        async add() {
            this.curSigner = [];
            const item = Object.assign({});

            this.$set(item, 'id_user', this.users.id_user);
            if (this.modules.length > 0 && this.modules[0] === 'all') {
                this.$set(item, 'code_modules', this.selectModule.slice(1).map(option => option.code));
            } else {
                this.$set(item, 'code_modules', this.modules);
            }
            if (this.PayForm.length > 0 && this.PayForm[0] === 'all') {
                this.$set(item, 'code_forms', this.selectPayForm.slice(1).map(option => option.code));
            } else {
                this.$set(item, 'code_forms', this.PayForm);
            }
            this.$set(item, 'code_sign', this.position.code);
            this.$set(item, 'update_date', moment().format('YYYY-MM-DD HH:mm:ss'));
            if (this.version === 0) {
                const ordersForCodeGu = this.signList.filter(order => Number(order.code_gu) === Number(this.selectedGUOptions.code));
                let maxOrder = ordersForCodeGu.length > 0 ? Math.max(...ordersForCodeGu.map(order => order.order_num)) : 0;
                if (isNaN(maxOrder)) {
                    maxOrder = 0;
                }
                let orderNum = maxOrder + 1;
                while (ordersForCodeGu.some(order => order.order_num === orderNum)) {
                    orderNum++;
                }
                this.$set(item, 'order_num', orderNum);
            } else if (this.version === 1){
                const ordersForCodeGu = this.signList.filter(order => Number(order.code_gu) === Number(this.selectedGkpk.code));
                let maxOrder = ordersForCodeGu.length > 0 ? Math.max(...ordersForCodeGu.map(order => order.order_num)) : 0;
                if (isNaN(maxOrder)) {
                    maxOrder = 0;
                }
                let orderNum = maxOrder + 1;
                while (ordersForCodeGu.some(order => order.order_num === orderNum)) {
                    orderNum++;
                }
                this.$set(item, 'order_num', orderNum);
            }
            this.$set(item, 'name_kz', this.kazakhName);
            this.$set(item, 'name_ru', this.russianName);
            this.$set(item, 'user_name', this.users.username);
            this.$set(item, 'start_date', moment(this.DateFrom).format('YYYY-MM-DD'));
            this.$set(item, 'end_date', this.DateTo ? moment(this.DateTo).format('YYYY-MM-DD') : null);

            const allOptionIndex = this.selectPrg.findIndex(option => option === 'all');
            if (allOptionIndex !== -1) {
                this.$set(item, 'code_prg', this.selectProgram.map(program => program.prg));
            } else {
                this.$set(item, 'code_prg', this.selectPrg);
            }

            this.$set(item, 'code_region', this.region ? this.region.code : null);
            this.$set(item, 'code_abp', this.abp.abp);
            if (this.version === 0) {
                this.$set(item, 'code_gu', this.selectedGu.code);
            } else if (this.version === 1) {
                this.$set(item, 'code_gu', this.selectedKGKP.code);
            }
            this.$set(item, 'version', this.version);
            this.isLoad = true;
            this.curSigner.push(item);
            // console.log(this.curSigner);
            try {
                const response = await fetch('/api-py/add_new_signatories', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.curSigner)
                });
                const result = await response.json();
                if (response.status === 200 && result.result === 'success') {
                    this.modalForm = false;
                    this.isSave = false;
                    this.makeToast('success', 'Сообщение', 'Данные обновлены');
                    await this.getSignatoriesList();
                } else {
                    if (result.result === 'error' && result.message.includes('already exists')) {
                        throw `Данный пользователь с такой должностью уже существует`;
                    } else {
                        throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                    }
                }

            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        },
        async update() {
            const items = [{
                id: this.editItem.id,
                id_user: this.users ? this.users.id_user : this.editItem.id_user,
                code_modules: this.modules.length > 0 && this.modules[0] === 'all'
                    ? this.selectModule.slice(1).map(option => option.code)
                    : this.modules,
                code_forms: this.PayForm.length > 0 && this.PayForm[0] === 'all'
                    ? this.selectPayForm.slice(1).map(option => option.code)
                    : this.PayForm,
                code_sign: this.position ? this.position.code : this.editItem.code_sign,
                order_num: this.editItem.order_num,
                name_kz: this.kazakhName ? this.kazakhName : this.editItem.name_kz,
                name_ru: this.russianName ? this.russianName : this.editItem.name_ru,
                update_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                user_name: this.users ? this.users.username : this.editItem.user_name,
                start_date: moment(this.DateFrom).format('YYYY-MM-DD'),
                end_date: this.DateTo ? moment(this.DateTo).format('YYYY-MM-DD') : null,
                code_prg: this.selectPrg.length > 0 && this.selectPrg[0] === 'all'
                    ? this.selectPrgOptions.slice(1).map(option => option.code)
                    : this.selectPrg,
                code_region: this.region ? this.region.code : this.editItem.code_region,
                code_abp: this.abp ? this.abp.abp : this.editItem.code_abp,
                code_gu: this.version === 0 ? (this.selectedGu ? this.selectedGu.code : this.editItem.code_gu) :
                    this.version === 1 ? (this.selectedKGKP ? this.selectedKGKP.code : this.editItem.code_gu) :
                        this.editItem.code_gu
            }]
            // console.log(items);
            try {
                const response = await fetch(`/api-py/update_signatories`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(items)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.modalForm = false;
                    this.isSave = false;
                    this.makeToast('success', 'Сообщение', 'Данные обновлены');
                    await this.getSignatoriesList();
                } else {
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        },
        async delete(item, index) {
            try {
                const response = await fetch('/api-py/delete-signatories', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.signList.splice(index, 1);
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            }
        }, // удаление данных
        deleteSignatories(row, index) {
            this.$bvModal.msgBoxConfirm(
                'Удаленная запись не подлежит восстановлению. Вы уверены?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.delete(row, index);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }
    }
}
</script>

